// Glide
// Required Core Stylesheet
import "@glidejs/glide/src/assets/sass/glide.core.scss";
// Optional Theme Stylesheet
import "@glidejs/glide/src/assets/sass/glide.theme.scss";

import Glide from '@glidejs/glide';

window.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('#homepage-slider').querySelector('.glide__slide') !== null) {
        const glideCarousel = new Glide('#homepage-slider', {
            type: 'carousel',
            perView: 1,
            gap: 0,
        });
        glideCarousel.mount();
    }
});
